import { useEffect, useRef, useState } from 'react';
import { DotPadSDK } from "./DotPadSDK-1.0.0"
import "./App.css";

export default function DotPad() {
  // Constants for full graphic and text output
  const CELL300_GRAPHIC_FULL = "9ff9";
  const CELL300_TEXT_FULL = "000000000008800888800080000808000000000008800008000808800008000010000010000111100001100011000010000001100100100001100010000040000024000040000006200406200400000400200642400600000000000000800808800800000000000000000000000000000000000000000000000000100000000100000000000000000000000000000000000000000000000040000066200464000040600042600042000040200642200000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000";
  const CELL300_GRAPHIC_PARTIAL = "111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111";
  const CELL300_TEXT_PARTIAL = "312536";
  const CELL20_TEXT_SHORT = "19151E";
  const CELL20_TEXT_FULL = "19151E001E15190019151E001E15190019151E001E15190019151E001E151900";

  // Device interface
  interface Device {
    target: null;
    name: string;
    connected: boolean;
  }

  const dotpadsdk = useRef<DotPadSDK>();
  const [devices, setDevices] = useState<Device[]>([]);
  const [textboxValue, setTextboxValue] = useState('');
  const [spinBoxValue, setSpinBoxValue] = useState<number>(2);
  const x1Ref = useRef<HTMLInputElement>(null);
  const y1Ref = useRef<HTMLInputElement>(null);
  const x2Ref = useRef<HTMLInputElement>(null);
  const y2Ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    dotpadsdk.current = new DotPadSDK();
  }, []);

  // Function to update device connection information
  const updateDeviceConnection = async (device: any, connected: any) => {
    if (connected) {
      const isConnected = await dotpadsdk.current?.connect(device.target);
      if (isConnected) {
        await dotpadsdk.current?.addListenerKeyEvent(device.target, dotpadKeyCallback);
      }
    } else {
      await dotpadsdk.current?.disconnect(device.target);
    }
    setDevices(devices => devices.map(d => d.name === device.name ? { ...d, connected } : d));
  };

  // Function to select a DotPad device
  const handleSelectDevice = async () => {
    const device = await dotpadsdk.current?.request();
    const deviceInfo = {
      target: device,
      name: device.name,
      connected: false
    };
    setDevices(currentDevices => [...currentDevices, deviceInfo]);
  };

  // Function to print full graphic area (300 cells)
  const handleCell300FullPrint = async (hexData: string) => {
    devices.map(async (device) => {
      await dotpadsdk.current?.displayGraphicData(device, hexData);
    });
  };

  const drawLine = async () => {
    const x1 = Number(x1Ref.current?.value);
    const y1 = Number(y1Ref.current?.value);
    const x2 = Number(x2Ref.current?.value);
    const y2 = Number(y2Ref.current?.value);
    console.log(`Drawing line from (${x1}, ${y1}) to (${x2}, ${y2})`);
    try {
      const response = await fetch(`https://dot.rustytelephone.net/py/line?x1=${x1}&y1=${y1}&x2=${x2}&y2=${y2}`);
      const data = await response.json();
      const hexData = convertJsonToHex(data);  // You�ll need to implement this function based on your data structure
      updateTextboxValue(hexData);
      await handleCell300FullPrint(textboxValue);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const drawRectangle = async () => {
    const x1 = Number(x1Ref.current?.value);
    const y1 = Number(y1Ref.current?.value);
    const x2 = Number(x2Ref.current?.value);
    const y2 = Number(y2Ref.current?.value);
    console.log(`Drawing rectangle from (${x1}, ${y1}) to (${x2}, ${y2})`);
    try {
      const response = await fetch(`https://dot.rustytelephone.net/py/rectangle?x1=${x1}&y1=${y1}&x2=${x2}&y2=${y2}`);
      const data = await response.json();
      const hexData = convertJsonToHex(data);  // You�ll need to implement this function based on your data structure
      updateTextboxValue(hexData);
      await handleCell300FullPrint(textboxValue);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const drawGrid = async () => {
    try {
      const response = await fetch(`https://dot.rustytelephone.net/py/grid?size=${spinBoxValue}`);
      const data = await response.json();
      const hexData = convertJsonToHex(data);  // You�ll need to implement this function based on your data structure
      updateTextboxValue(hexData);
      await handleCell300FullPrint(textboxValue);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const convertJsonToHex = (data: any): string => {
    // Implement this based on your specific data structure and needs
    return data.hex;  // Example
  };

const combineHexStrings = (hex1: string, hex2: string): string => {
    // Parse the hex strings into numbers
    const num1 = BigInt('0x' + (hex1 || '0'));
    const num2 = BigInt('0x' + (hex2 || '0'));

    // Perform the logical OR operation
    const combinedNum = num1 | num2;

    // Convert the result back to a hex string
    let combinedHex = combinedNum.toString(16);
console.log(`combining hex strings`);
console.log(`hex1 ${hex1} and hex2: ${hex2}`);
console.log(`num1: ${num1} and num2: ${num2}`);
console.log(`combines to: ${combinedNum}  or hex: ${combinedHex}`);
    // Determine the length to pad to (use the maximum length of the input hex strings)
    const maxLength = Math.max(hex1.length, hex2.length);

    // Pad the result with leading zeros
    combinedHex = combinedHex.padStart(maxLength, '0');

    return combinedHex;
};

const updateTextboxValue = (hex2: string) => {
setTextboxValue(combineHexStrings(textboxValue || '0', hex2));
};

  // Function to print a portion of the graphic area (300 cells)
  const handleCell300PartialPrint = async (lineId: number, cellIndex: number, hexData: string) => {
    devices.map(async (device) => {
      await dotpadsdk.current?.displayGraphicLineData(device, lineId, cellIndex, hexData);
    });
  };

  // Function to reset the graphic area (300 cells)
  const handleCell300Reset = async () => {
    devices.map(async (device) => {
      await dotpadsdk.current?.resetGraphicData(device);
    });
  };

  // Function to print the text area (20 cells)
  const handleCell20Print = async (hexData: string) => {
    devices.map(async (device) => {
      await dotpadsdk.current?.displayTextData(device, hexData);
    });
  };

  // Function to reset the text area (20 cells)
  const handleCell20Reset = async () => {
    devices.map(async (device) => {
      await dotpadsdk.current?.resetTextData(device);
    });
  };

  // DotPad function key callback
  const dotpadKeyCallback = async (keyCode: string) => {
    console.log("=> dotpad key code : " + keyCode);
  }

  return (
      <div className="tableContainer">
        <div className="buttonContainer">
          <button className="selectButton" onClick={handleSelectDevice}>
            Select DotPad
          </button>
        </div>
        <table className="table">
          <thead>
            <tr>
              <th className="header">DotPad Name</th>
              <th className="header">Connect/Disconnect</th>
            </tr>
          </thead>
          <tbody>
            {devices.map((device) => (
              <tr key={device.name} className="row">
                <td className="cell">{device.name}</td>
                <td className="cell">
                  {!device.connected && (
                    <button className="button" onClick={() => updateDeviceConnection(device, true)}>
                      Connect
                    </button>
                  )}
                  {device.connected && (
                    <button className="button" onClick={() => updateDeviceConnection(device, false)}>
                      Disconnect
                    </button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      <div className="container">
        <div className="labelContainer">
          <label>Draw a line:</label>
        </div>
        <div className="buttonContainer">
          <label>
            X1:
            <input type="number" min="0" max="59" defaultValue="0" ref={x1Ref} />
          </label>
          <label>
            Y1:
            <input type="number" min="0" max="39" defaultValue="0" ref={y1Ref} />
          </label>
          <label>
            X2:
            <input type="number" min="0" max="59" defaultValue="0" ref={x2Ref} />
          </label>
          <label>
            Y2:
            <input type="number" min="0" max="39" defaultValue="0" ref={y2Ref} />
          </label>
          <button className="selectButton" onClick={drawLine}>
            Draw Line
          </button>

          <button className="selectButton" onClick={drawRectangle}>
            Draw Rectangle
          </button>
        </div>
      </div>
        <div className="container">
          <div className="labelContainer">
            <label>Draw a Grid:</label>
          </div>
          <div className="buttonContainer">
            <input
              type="number"
              value={spinBoxValue}
              onChange={(e) => setSpinBoxValue(Number(e.target.value))}
              min="2"
              max="40"
            />
            <button className="selectButton" onClick={() => {
drawGrid();
}}>
              draw grid
            </button>
          </div>
        </div>
        <div className="container">
          <div className="labelContainer">
            <label>Enter Hex String To Print</label>
          </div>
          <div className="buttonContainer">
            <textarea
            rows={10}
            cols={60}
            value={textboxValue}
            onChange={(e) => setTextboxValue(e.target.value)}
          />
            <button className="selectButton" onClick={() => {
              handleCell300FullPrint(textboxValue);
            }}>
              Print Textbox
            </button>
            <button className="selectButton" onClick={() => {
              handleCell300Reset();
setTextboxValue('')
            }}>
              Reset
            </button>

            <button className="selectButton" onClick={() => {
              handleCell300FullPrint("9ff909ff90");
            }}>
              Print Random Full
            </button>
          </div>
        </div>
        <div className="container">
          <div className="labelContainer">
            <label>Graphic Area (300 cells)</label>
          </div>
          <div className="buttonContainer">
            <button className="selectButton" onClick={() => {
              handleCell300FullPrint(CELL300_GRAPHIC_FULL);
            }}>
              Print Full Image
            </button>
            <button className="printButton" onClick={() => {
              handleCell300PartialPrint(0, 0, CELL300_GRAPHIC_PARTIAL);
            }}>
              Print Partial Image
            </button>
            <button className="selectButton" onClick={() => {
              handleCell300FullPrint(CELL300_TEXT_FULL);
            }}>
              Print Full Braille
            </button>
            <button className="printButton" onClick={() => {
              handleCell300PartialPrint(3, 10, CELL300_TEXT_PARTIAL);
            }}>
              Print Partial Braille
            </button>
          </div>
        </div>
        <div className="container">
          <div className="labelContainer">
            <label>Text Area (20 cells)</label>
          </div>
          <div className="buttonContainer">
            <button className="selectButton" onClick={() => {
              handleCell20Print(CELL20_TEXT_SHORT);
            }}>
              Print Braille
            </button>
            <button className="printButton" onClick={() => {
              handleCell20Print(CELL20_TEXT_FULL);
            }}>
              Print Braille (Panning Button)
            </button>
            <button className="selectButton" onClick={() => {
              handleCell20Reset();
            }}>
              Reset
            </button>            
          </div>
        </div>
      </div>
  );
}
